
import { Link } from "react-router-dom";
import _ from 'lodash';
import { Carousel } from "decorators";
import { SuspenseImg } from "utility";
import { useProducts } from "queries";
function SamplePriv() {
    const { data } = useProducts();
    return (<>
        <div className="section-title">สิทธิพิเศษที่น่าสนใจ</div>
        <Carousel number={2}>
            {_.sampleSize(data, 4).map((r, i) => (
                <Link className="item px-1" key={r.name} to={`/privilege/${r.group}/${r.name}`} style={{ display: 'inline-block' }}>
                    <SuspenseImg style={{ boxShadow: "0 0 5px #C8b386" }} className="img" src={r.image} />
                </Link>
            ))}
        </Carousel>
    </>);
}

export default SamplePriv;