import { Link, useRouteMatch } from "react-router-dom";
import { SuspenseImg } from "utility";
import Splitter from "decorators/Splitter";
import categories from 'categories.json';
import { useProducts } from "queries";
import _ from 'lodash';
import './browse.css';
const Browse = function () {
    return (
        <>
            <SuspenseImg className="image margin-auto" src="/assets/priv/banner-default.png" />
            <Splitter />
            {categories.map((t, idx) =>
                <div className="privRowContainer" key={idx}>
                    <div className="subtitle is-6 mb-0" style={{ textTransform: 'capitalize' }}>{t.group}</div>
                    <PrivRowContainer group={t} />
                </div>
            )}
        </>
    );
};
export default Browse;

const PrivRowContainer = ({ group: t }) => {
    const { path } = useRouteMatch();
    const { data } = useProducts(t.group);
    if (!data) return <></>;
    let browseSample = _.sampleSize(data, 4);
    return browseSample.map((r, i) =>
        <Link key={r.image} to={`${path}/${t.group.toLowerCase()}${(i !== browseSample?.length - 1) ? '/' + r.name : ''}`} className="SuspenseImgContainer">
            <SuspenseImg className="privImage" src={r.image} /> {(i === browseSample?.length - 1) && <div className="moreBtn">More +</div>}
        </Link>
    );
};