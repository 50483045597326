import { Link } from "react-router-dom";
import { Splitter } from "decorators";
import { SuspenseImg } from "utility";

function Results() {
    return <>
        <SuspenseImg className="image margin-auto" src="/assets/activity/ลงทะเบียนสำเร็จ.png"  />
        <Splitter />
        <div className="mt-3" style={{ textAlign: 'center' }}>
            <Link to='/activity' style={{ padding: '0.5rem', borderRadius: '12px', fontSize: '15px' }} className="gold-1 gold-border">
                กลับหน้ากิจกรรม
            </Link>
        </div>
    </>;
}
export default Results;