import axios from 'axios';
import { mutate } from 'swr';
const Swal = window.Swal;
async function buyIt(props, type, id, history) {
    const { value } = await Swal.fire({
        title: "กรุณากรอก " + (type === 'email' ? 'Email' : 'ชื่อ-ที่อยู่') + " สำหรับจัดส่งของรางวัล",
        input: type === 'email' ? 'email' : 'textarea',
        showCancelButton: true,
        inputValue: type === 'email' ? props.profile.email || '' : props.profile.address || '',
    });
    if (!value) return;
    try {
        if (value !== (type === 'email' ? props.profile.email : props.profile.address)) {
            let updateUser = {};
            if (type === 'email') {
                updateUser = { ...props.profile, email: value };
            } else {
                updateUser = { ...props.profile, address: value };
            }
            props.setLoading(true);
            await axios.put(window.api + '/members/line/' + props.profile.lineId, updateUser);
            props.setLoading(false);
        }
        await axios.post(window.api + '/products/buy/', { lineId: props.profile.lineId, productId: id });
        await Swal.fire({
            title: "แลก U-Coin สำเร็จ!",
            icon: "success",
        });
        mutate(props.profile.lineId);
        history.push(history.location.pathname + '/completed', { type: type });
    } catch (e) {
        console.log(e);
        await Swal.fire({
            title: 'ผิดพลาด!',
            icon: 'error',
            text: e.response.data?.Error,
        });
        props.setLoading(false);
    }

}
export { buyIt };