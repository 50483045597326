import React, { Suspense, useEffect, useState } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { Notfound, Home, Browse, HomeDetals, Category, Product, Completed, Activity, ActivityItem, Profile, Results, PreRegis, Disabled } from "components";
import { NavFooter, Spinner } from "decorators";
import { useProfile } from 'queries';
import { mutate } from 'swr';
import axios from 'axios';
import ReactGA from 'react-ga';
window.api = process.env.REACT_APP_API;
const liff = window.liff;
const Swal = window.Swal;

function App() {
  const history = useHistory();
  const [lineProfile, setLineProfile] = useState({ displayName: false, pictureUrl: '' });
  const [isRegisterd, setRegister] = useState(false);
  const [loading, setLoading] = useState(true);
  const { data: profile } = useProfile(lineProfile.userId);
  // const [profile, setProfile] = useState({ userId: '', displayName: false, pictureUrl: '', coins: 0, class: 'Grandmaster' });
  useEffect(() => {
    async function init() {
      try {
        ///////////////////////////////////////
        let liffProfile;
        try {
          await liff.init({ liffId: process.env.REACT_APP_LIFF_ID });
          if (!liff.isLoggedIn()) return liff.login();
          liffProfile = await liff.getProfile();
          axios.interceptors.request.use(config => {
            config.headers.Authorization = liff.getAccessToken();
            return config;
          });
        } catch (e) {
          await Swal.fire({
            title: 'ผิดพลาด!',
            html: `<pre>${e}</pre>`,
            icon: 'error'
          })
          liff.logout();
          window.location.reload();
        }

        setLineProfile(liffProfile);
        await mutate(liffProfile.userId);
        if (process.env.NODE_ENV === "production") {
          ReactGA.initialize('UA-193525453-1', { debug: false })
          ReactGA.pageview(history.location.pathname)
          history.listen(location => {
            ReactGA.pageview(location.pathname)
          })
        }
        ///////////////////////////////////////
        setLoading(false);
        setRegister(true);
        if (history.location.pathname === '/register') return history.replace('/');
        if (history.location.pathname === '/404') return history.replace('/');
      } catch (e) {
        setLoading(false);
        return history.replace('/404');
      }
    }
    init();
  }, [history]);

  if (profile === undefined) return <Spinner />;
  if (profile === null) return <PreRegis lineProfile={lineProfile} setLoading={setLoading} />;
  // if (profile && profile.disabled === true) return <Disabled />;
  return (
    <>
      <Spinner style={{ display: `${!loading ? 'none' : "block"}` }} />
      <Suspense fallback={<Spinner />}>
        <div className="container" style={{ display: `${loading ? 'none' : "block"}` }}>
          <Switch>
            <Route path="/" render={() => <Home profile={profile} />} exact />
            <Route path="/home-details" render={() => <HomeDetals profile={profile} />} exact />
            <Route path="/privilege" render={() => <Browse />} exact />
            <Route path="/privilege/:category" render={() => <Category profile={profile} />} exact />
            <Route path="/privilege/:category/:product" render={() => <Product profile={profile} setLoading={setLoading} />} exact />
            <Route path="/privilege/:category/:product/completed" render={() => <Completed profile={profile} />} exact />
            <Route path="/activity" render={() => <Activity />} exact />
            <Route path="/activity/:item" render={() => <ActivityItem profile={profile} />} exact />
            <Route path="/activity/:item/completed" render={() => <Results />} exact />
            <Route path="/profile" render={() => <Profile profile={profile} setLoading={setLoading} />} exact />
            <Route component={Notfound} ></Route>
          </Switch>
          {isRegisterd && <NavFooter />}
        </div>
      </Suspense>
    </>
  );
}
export default App;
