import { DateTime } from 'luxon';
import { SuspenseImg, numberWithCommas } from "utility";
import { SamplePriv, Splitter } from "decorators";
import "./index.css";
import { useTransactions } from 'queries';
function HomeDetals({ profile }) {
    const { data: transactions } = useTransactions(profile.userId);
    return (
        <>
            <div className="card wrapper">
                <SuspenseImg src={`/assets/CARD---${profile.class}.png`} style={{ border: "1px solid #C8b386", borderRadius: '3px', boxShadow: "0 0 15px #C8b386", WebkitBoxShadow: "0 0 15px #C8b386" }} />
                <div className="text-inner">
                    <p className="subtitle is-6 mb-4">ยอด U-COINS คงเหลือ</p>
                    <p className="title is-3 mb-0"><b>{numberWithCommas(profile.coins)}</b></p>
                    <p className="ucoin mb-0">U-COINS</p>
                </div>
            </div>
            <Splitter />
            <div style={{ backgroundColor: "#2c2a25", padding: '2rem 0.5rem' }}>
                <span className="subtitle is-6 mb-0" style={{ textDecorationLine: "underline" }}>ประวัติการใช้ U-COINS</span>
                <table className="table is-fullwidth" style={{ border: "unset" }}>
                    <thead>
                        <tr style={{ backgroundColor: "#c8b386" }}>
                            <th>วันที่</th>
                            <th>รายการ</th>
                            <th>จำนวน COIN</th>
                        </tr>
                    </thead>
                    <tbody style={{ backgroundColor: "#2c2a25" }}>
                        {transactions.map(t =>
                            <tr key={t._id} style={{ borderBottom: "1px solid #C8b386" }}>
                                <td style={{ lineHeight: '0.8' }}><span style={{ fontSize: "10px" }}>{DateTime.fromISO(t.created).toFormat('dd-MM-yyyy')}</span>
                                    <br /><span style={{ fontSize: "10px" }}>{DateTime.fromISO(t.created).toFormat('HH:MM:ss')}</span></td>
                                <td>{t.product?.tagline || t.activity?.tagline || t.reason}</td>
                                <td>{t.changes} UCoin</td>
                            </tr>)}
                    </tbody>
                </table>
            </div>
            <Splitter />
            <SamplePriv />
        </>
    );
};

export default HomeDetals;