import { HashLoader } from 'react-spinners';
function Spinner({ style }) {
    return (<>
        <div style={{
            top: "30%",
            left: "47%",
            position: "absolute",
            ...style
        }}>
            <HashLoader color="#C8b386"></HashLoader>
        </div>
    </>);
}
export default Spinner;