import React from 'react';
import _ from 'lodash';
import { NavLink } from 'react-router-dom';
import "./style.css";

const path = [{ url: '/', icon: "homeIcon" },
{ url: '/privilege', icon: "privIcon" },
{ url: '/activity', icon: "activIcon" },
{ url: '/home-details', icon: "memberIcon" }];

function NavFooter() {
    return (<nav className="navbar is-fixed-bottom">
        <div className="navbar-brand">
            {path.map(({ url, icon }, idx) => (
                <PathNavLink key={idx} exact to={url} className="navbar-item is-expanded is-block">
                    <div className={`footer-icon ${icon}`} />
                </PathNavLink>
            ))}
        </div>
    </nav>);
}
const PathNavLink = (props) =>
    <NavLink isActive={(temp, { pathname }) => props.to === '/' ? pathname === '/' : _.startsWith(pathname, props.to)} {...props}>
        {props.children}
    </NavLink>;
// pathname === '/home-details' ||
export default NavFooter;