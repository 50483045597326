import { Redirect, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { SuspenseImg, numberWithCommas } from "utility";
import { Carousel, Splitter } from "decorators";
import _ from 'lodash';
import categories from 'categories.json';
import { useProducts } from "queries";

function Category({ profile }) {
    const history = useHistory();
    const { pathname } = history.location;
    const splitedPath = pathname.split('/').filter(t => t);
    const currentPath = splitedPath[splitedPath.length - 1];
    const currentGroup = _.find(categories, { group: currentPath });
    const { data: products } = useProducts(currentPath);
    if (!currentGroup) return <Redirect to="/404"></Redirect>;
    return (<>
        <SuspenseImg className="image margin-auto" src={currentGroup.groupImg} />
        <Splitter />
        <div className="has-text-right mr-3">
            <p className="title is-3 mb-0"><b>{numberWithCommas(profile.coins)}</b></p>
            <p className="ucoin mb-0">U-COINS</p>
        </div>
        <nav className="breadcrumb p-2 mb-0" aria-label="breadcrumbs" style={{ backgroundColor: "#605845" }}>
            <ul>
                <li><Link to="/">Home</Link></li>
                {splitedPath.map(t =>
                    <li key={t}><Link to={`${t === currentPath ? '/' + splitedPath.join('/') : '/' + t}`} style={{ textTransform: 'capitalize' }}>{t}</Link></li>
                )}
            </ul>
        </nav>
        <div className="pt-3">
            <Carousel number={1} paddingRight={120}>
                {_.shuffle(products).map((t, i) =>
                    <div className="card mx-2 p-3 gold-3" style={{ borderRadius: 10 }} key={i}>
                        <div className="card-image">
                            <figure className="image">
                                <SuspenseImg src={t.image} />
                            </figure>
                        </div>
                        <div className="card-content p-1" style={{ textAlign: 'center', minHeight: '15vh' }}>
                            <div className="content pb-0" style={{ fontSize: "0.7rem", textAlign: 'left' }}>
                                <div className="gold-1" style={{ fontSize: '0.8rem' }}>{t.name}</div>
                                {/* <span style={{ padding: '0.2rem', backgroundColor: '#C8b386', borderRadius: 12, fontSize: '0.8rem' }}>{t.price} U-COINS</span> */}
                                <p className="gold-1 details">
                                    <b>{t.tagline}</b>
                                </p>
                            </div>
                            <Link className="buttonLink" to={currentPath + '/' + t.name}>
                                <SuspenseImg width="35%" src="/assets/buttons/checkout.png" />
                            </Link>
                        </div>
                    </div>
                )}
            </Carousel>
        </div>
    </>);
}

export default Category;