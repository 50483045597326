function Notfound() {
    return (
        <div className="container">
            <div className="pt-6">
                <div className="columns is-vcentered">
                    <div className="column has-text-centered">
                        <h1 className="title">หมดโปรโมชั่น หรือเกิดข้อผิดพลาด</h1>
                        <p className="subtitle">สามารถสอบถามข้อมูลกับเจ้าหน้าที่เพิ่มเติม ได้ที่ไลน์ @365GRANDS</p>
                    </div>
                </div>
            </div>
        </div>);
};

export default Notfound;