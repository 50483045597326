import { Link } from "react-router-dom";
import _ from 'lodash';
import { Carousel, Splitter } from "decorators";
import { SuspenseImg, numberWithCommas } from "utility";
import { useProducts, useActivity } from "queries";
function Home({ profile }) {
    const { data } = useProducts();
    const activity = useActivity();
    const activityData = _.partition(activity.data, { type: 'now' })
    return (
        <>
            <div className="card">
                <div className="card-content pb-0" style={{ paddingBottom: '1rem' }}>
                    <div style={{ float: 'left', clear: 'both' }}>
                        <figure>
                            <SuspenseImg className="image" style={{ height: '120px' }} src="/assets/365Grands.png" />
                        </figure>
                    </div>
                    <div className="content mb-0" style={{ textAlign: "right" }}>
                        <p className="subtitle is-6 mb-0">{profile.userId}</p>
                        <p className="subtitle is-7 has-text-white mb-1">Class: {profile.class}</p>
                    </div>
                    <div className="media">
                        <div className="media-left">
                        </div>
                        <div className="media-content">
                        </div>
                        <div className="media-right">
                            <figure className="image is-64x64">
                                <SuspenseImg style={{ "borderRadius": "30px", border: "2px solid #C8b386" }} width="64" height="64" src={profile.imageURL} alt="Profile" />
                            </figure>
                        </div>
                    </div>
                    <div className="content" style={{ textAlign: "right" }}>
                        <p className="title is-3 mb-0"><b>{numberWithCommas(profile.coins)}</b></p>
                        <p className="ucoin mb-0">U-COINS</p>
                        <Link to="/home-details">
                            <SuspenseImg width="35%" src="/assets/buttons/ptdetails.png" />
                        </Link>
                    </div>
                </div>
            </div>
            <a href="https://bit.ly/Line-365grands" target='_blank'>
                <img style={{
                    position: "fixed",
                    paddingLeft: '33%', 
                    paddingRight: '25%',
                    bottom:'12%',
                    left:'20%',
                    zIndex:'5'
                }}
                    src="/assets/addline-365Grands.png" />
            </a>
            <Splitter />
            <div className="section-title">ไฮไลท์กิจกรรม</div>
            <Carousel number={1} paddingRight={100} >
                {activityData[0].map((t, i) =>
                    <Link to={`/activity/${t._id}`} className="item px-1 pt-2" key={t.name} style={{ display: 'inline-block' }}>
                        <SuspenseImg className="img" src={t.image} />
                    </Link>
                )}
            </Carousel>
            <Splitter />
            <div className="section-title">สิทธิพิเศษที่น่าสนใจ</div>
            <Carousel number={2}>
                {_.sampleSize(data, 4).map((r, i) => (
                    <Link className="item px-1" key={r.name} to={`/privilege/${r.group}/${r.name}`} style={{ display: 'inline-block' }}>
                        <SuspenseImg style={{ boxShadow: "0 0 5px #C8b386" }} className="img" src={r.image} />
                    </Link>
                ))}
            </Carousel>
        </>
    );
};

export default Home;