import { Redirect, useHistory } from "react-router";
import { useState } from "react";
import { SamplePriv, Carousel, Splitter } from "decorators";
import { SuspenseImg, buyIt } from "utility";
import _ from 'lodash';
import ReactHtmlParser from "react-html-parser";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useProducts } from "queries";
function Product(props) {
    const [lightBox, setLightbox] = useState({
        photoIndex: 0,
        isOpen: false,
    });
    const history = useHistory();
    const { pathname } = history.location;
    const splitedPath = pathname.split('/').filter(currentItem => currentItem);
    const currentPath = splitedPath[splitedPath.length - 1];
    const { data } = useProducts(splitedPath[splitedPath.length - 2]);
    let currentItem = _.find(data, { name: currentPath });
    if (currentItem === undefined) return <Redirect to="/404"></Redirect>;
    return (<>
        <div className="card-image">
            <figure className="image">
                <SuspenseImg src={currentItem.fullImage} style={{ boxShadow: "0 0 15px #C8b386" }} />
            </figure>
        </div>
        <Splitter />
        <div className="card-content p-1" style={{ textAlign: 'center', minHeight: '19vh' }}>
            <div className="content mb-1" style={{ fontSize: "0.7rem", textAlign: 'left' }}>
                <div className="gold-1" style={{ fontSize: '1.3rem' }}>{currentItem.name}</div>
                <p className="gold-1 details mb-3" style={{ fontSize: '0.8rem' }}>
                    <b>{ReactHtmlParser(currentItem.details)}</b>
                </p>
                <div className="py-4" style={{ textAlign: 'center' }}>
                    {props.profile.coins >= currentItem.price ?
                        <span onClick={() => { buyIt(props, currentItem.type, currentItem._id, history); }} style={{ padding: '0.5rem', borderRadius: '12px', fontSize: '15px', boxShadow: "0 0 15px #C8b386" }} className="gold-1 gold-border">
                            ยืนยันการแลกสิทธิ์ (ใช้ {currentItem.price} U-Coins)</span>
                        : <span style={{ padding: '0.5rem', borderRadius: '12px', fontSize: '15px', boxShadow: "0 0 15px black" }} className="gold-1 gold-border">Coin ของคุณไม่เพียงพอ</span>
                    }
                </div>
            </div>
            {/* <p className="gold-1 details">
                <b><u>ขั้นตอนการใช้งาน</u></b><br />
            </p>
            {_.isArray(currentItem.tutorials) ?
                <Carousel number={3} infinite={false} paddingRight={currentItem.tutorials.length > 3 ? 100 : 0}>
                    {currentItem.tutorials.map((t, i) =>
                        <div className="item px-1" key={i} onClick={(e) => {
                            e.preventDefault();
                            setLightbox({ isOpen: true, photoIndex: i });
                        }}>
                            <SuspenseImg className="img gold-border" style={{ boxShadow: "0 0 5px #C8b386" }} src={t} />
                        </div>
                    )}
                </Carousel>
                :
                <p className="gold-1" style={{ textAlign: 'left', fontSize: "0.7rem" }} >{ReactHtmlParser(currentItem.tutorials)}</p>
            } */}
            <Splitter />
        </div>
        <SamplePriv></SamplePriv>
        {
            lightBox.isOpen && (
                <Lightbox
                    mainSrc={currentItem.tutorials[lightBox.photoIndex]}
                    nextSrc={currentItem.tutorials[(lightBox.photoIndex + 1) % currentItem.tutorials.length]}
                    prevSrc={currentItem.tutorials[(lightBox.photoIndex + currentItem.tutorials.length - 1) % currentItem.tutorials.length]}
                    onCloseRequest={() => setLightbox({ isOpen: false, photoIndex: lightBox.photoIndex })}
                    onMovePrevRequest={() => setLightbox({ photoIndex: (lightBox.photoIndex + currentItem.tutorials.length - 1) % currentItem.tutorials.length, isOpen: lightBox.isOpen })}
                    onMoveNextRequest={() => setLightbox({ photoIndex: (lightBox.photoIndex + 1) % currentItem.tutorials.length, isOpen: lightBox.isOpen })}
                />
            )
        }
    </>);
}

export default Product;