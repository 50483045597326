import { Formik, Form, Field, ErrorMessage } from 'formik';
import { getIconColor, getInputColor, SuspenseImg } from "utility";
import { Register } from "components";
import axios from 'axios';
import { useState } from "react";
import _ from 'lodash';
import * as yup from 'yup';
const Swal = window.Swal;
export default function PreRegis({ lineProfile, setLoading }) {
    const [clickNumber, setClickNumber] = useState(0);
    const [status, setStatus] = useState({ success: false, userId: '' });
    if (status.success) return <Register lineProfile={lineProfile} setLoading={setLoading} userId={status.userId} />
    return (
        <div className="mx-5 pt-5" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignContent: 'center' }}>
            <Formik
                initialValues={{ userId: '' }}
                validationSchema={yup.object({
                    userId: yup.string().min(9, 'กรุณากรอกอย่างน้อย 9 ตัวอักษร').required().test('checkUserId', 'userId ต้องเริ่มต้นด้วย ufa36', (value, context) => new Promise(resolve => preValidate(resolve, value, context))),
                })}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        const post = await axios.get(window.api + '/lookup/?userId=' + values.userId.toLowerCase());
                        if (post.errors) {
                            setSubmitting(false);
                            return Swal.fire({
                                title: 'ผิดพลาด!',
                                text: post.message,
                                icon: 'error'
                            });
                        }
                        setStatus({ success: true, userId: values.userId.toLowerCase() })
                    } catch (e) {
                        await Swal.fire({
                            title: 'ผิดพลาด!',
                            text: e.response.data?.Error,
                            icon: 'error'
                        });
                    }
                }}
            >{({ errors, touched, submitForm }) => (
                <Form className='p-5' style={{ color: 'white', backgroundColor: '#4b4a48', borderRadius: '15px', marginTop: '40%' }}>
                    {clickNumber > 4 && <pre onClick={() => navigator.clipboard.writeText(lineProfile.userId)}>{lineProfile.userId}</pre>}
                    <div className="has-text-centered pb-4" style={{ fontSize: '1.2rem' }} onClick={() => setClickNumber(clickNumber + 1)}>เช็คชื่อผู้ใช้</div>
                    <div className="field">
                        <label className="label" style={{ color: 'white' }}>ชื่อผู้ใช้<span style={{ color: 'red' }}>**</span></label>
                        <div className="control has-icons-left has-icons-right">
                            <Field className={`input ${getInputColor(errors.userId, touched.userId)}`} type="text" name="userId" placeholder="ufa36sgaXXXX" />
                            <span className={`icon is-small is-left ${getIconColor(errors.userId, touched.userId)}`}>
                                <i className="fas fa-user"></i>
                            </span>
                            <span className={`icon is-small is-right ${getIconColor(errors.userId, touched.userId)}`}>
                                <i className={`fas fa-check `}></i>
                            </span>
                        </div>
                        <ErrorMessage name="userId" component="p" className="help is-danger" />
                    </div>
                    <div className="field is-grouped is-grouped-centered">
                        <div className="control has-text-centered">
                            {/* <SuspenseImg width="60%" src="/assets/buttons/confirm.png" onClick={errors.userId ? null : submitForm} /> */}
                            <span onClick={errors.userId ? null : submitForm}>Confirm</span>
                        </div>
                    </div>
                </Form>)}
            </Formik>
            <a href="https://bit.ly/Line-365grands" target='_blank'>
                <img style={{
                    marginLeft: 'auto', marginRight: 'auto',
                    paddingLeft: '20%', paddingRight: '20%',
                    paddingTop: '15px'
                }}
                    src="/assets/addline-365Grands.png" />
            </a>
        </div>
    )
}

const validationDebounced = async (resolve, value, context) => {
    if (!/^ufa36/.test(value) || value.length < 9) return resolve(false);
    const formattedValue = _.trim(value)
    const valid = await axios.get(window.api + '/lookup/?userId=' + formattedValue.toLowerCase());
    if (valid.data === 'Found') {
        return resolve(true)
    }
    resolve(context.createError({ message: valid.data, path: 'userId' }))
}
const preValidate = _.debounce(validationDebounced, 600)