// import SuspenseImg from './SuspenceImg.js';
import { useState } from 'react';
import { buyIt } from './buyIt';
function getInputColor(errors, touched) {
    let color = '';
    if (errors && touched) {
        color = "is-danger";
    }
    if (touched && !errors) {
        color = "is-success";
    }
    return color;
}
function getIconColor(errors, touched) {
    let color = '';
    if (errors && touched) {
        color = "has-text-danger";
    }
    if (touched && !errors) {
        color = "has-text-success";
    }
    return color;
}
const SuspenseImg = ({ src, alt, style, fallback, ...rest }) => {
    const [isLoaded, setLoaded] = useState(false);
    return <>
        <img
            src={src}
            alt={alt}
            style={isLoaded ? style : { display: 'none' }}
            onLoad={() => {
                setLoaded(true);
            }}
            {...rest}
        />
        {!isLoaded && (fallback || <p>Loading</p>)}
    </>;
};
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export {
    getIconColor,
    getInputColor,
    SuspenseImg,
    buyIt,
    numberWithCommas
};