import useSWR from 'swr';
import axios from 'axios';
import { remove } from 'lodash';
function useProfile(id) {
    return useSWR(id, userFetcher, { revalidateOnMount: false, revalidateOnFocus: false, revalidateOnReconnect: false });
}
const useProducts = (category) => {
    return useSWR(['products', category], productFetcher, { suspense: true, revalidateOnMount: false, revalidateOnFocus: false, revalidateOnReconnect: false });
};
const useTransactions = (id) => {
    return useSWR(['transactions', id], transactionFetcher, { suspense: true, revalidateOnMount: false, revalidateOnFocus: false, revalidateOnReconnect: false });
};
const useActivity = (id, userId) => {
    return useSWR(['Activity', id, userId], activityFetcher, { suspense: true, revalidateOnMount: false, revalidateOnFocus: true, revalidateOnReconnect: false });
};


const transactionFetcher = async (transactions, id) => {
    let request = await axios.get(window.api + '/transactions/line/' + id);
    return request.data;
};

const userFetcher = async (id) => {
    let request = await axios.get(window.api + '/members/line/' + id);
    return request.data;
};

const productFetcher = async (product, category) => {
    let request = await axios.get(window.api + '/products/', { params: { group: category } });
    remove(request.data, { disabled: true })
    return request.data;
};
const activityFetcher = async (activity, id, userId) => {
    let request = await axios.get(id ? `${window.api}/activity/${id}?userId=${userId}` : `${window.api}/activity`);
    return request.data;
};

export { useProfile, useProducts, useTransactions, useActivity };