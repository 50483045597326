import { Link, useHistory } from "react-router-dom";
import { Splitter } from "decorators";
import { SuspenseImg } from "utility";

function Completed({ profile }) {
    const { location: { state } } = useHistory();
    return <>
        <SuspenseImg className="image margin-auto" src="/assets/priv/banner-completed.png" />
        <Splitter />
        <div className="gold-border has-text-centered has-text-white" style={{ background: "url('/assets/priv/cover-completed.png')", backgroundSize: '100% 100%' }}>
            {state.type === 'email' ?
                <p className='px-2 py-6' style={{ fontSize: '1.5rem' }}>
                    E-mail : <span><u>{profile.email}</u></span><br />
                    กรุณาตรวจสอบของรางวัลที่<br />e-mail ของท่าน<br /><br />**จัดส่งของรางวัลภายใน 1-3 วัน<br />หลังจากการแลกสิทธิ์<br />หากไม่ได้รับของรางวัลภายในระยะเวลาที่ระบุ กรุณาติดต่อแอดมิน**
                </p>
                : <p className='px-2 py-6' style={{ fontSize: '1.5rem' }}>
                    ที่อยู่ : <span>{profile.address}</span><br />
                เบอร์ติดต่อ : {profile.phoneNumber}<br /><br />**จัดส่งของรางวัลภายใน 7-15 วัน<br />หลังจากการแลกสิทธิ์<br />หากไม่ได้รับของรางวัลภายในระยะเวลาที่ระบุ กรุณาติดต่อแอดมิน**
            </p>
            }
        </div>
        <div className="has-text-centered pt-2">
            <Link to='/privilege'>
                <SuspenseImg width="50%" src="/assets/buttons/goPriv.png" />
            </Link>
            <Link to='/home-details'>
                <SuspenseImg width="50%" src="/assets/buttons/goHome.png" />
            </Link>
        </div>
    </>;
}
export default Completed;

