function Disabled() {
    return (
        <div className="container">
            <div className="pt-6">
                <div className="columns is-vcentered">
                    <div className="column has-text-centered">
                        <h1 className="title">มีข้อผิดพลาด</h1>
                        <p className="subtitle">แอคเคาท์คุณไม่สามารถใช้งานได้ กรุณาติดต่อเจ้าหน้าที่</p>
                    </div>
                </div>
            </div>
        </div>);
};

export default Disabled;