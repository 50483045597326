import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

function Carousel({ number = 2, children, paddingRight = 100, infinite = true, ...rest }) {
    return (<AliceCarousel {...rest} {...carouselConfig(number, paddingRight, infinite)}>
        {children}
    </AliceCarousel>);
}

const carouselConfig = (num, paddingRight, infinite) => ({
    paddingRight: paddingRight,
    disableDotsControls: true,
    disableButtonsControls: true,
    infinite: infinite,
    responsive: {
        0: {
            items: num,
        }
    }
});
export default Carousel;

