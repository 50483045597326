import { useHistory } from "react-router";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import { Splitter } from "decorators";
import { SuspenseImg } from "utility";
import { useActivity } from "queries";
import { mutate } from 'swr';
import axios from 'axios';
const Swal = window.Swal;
function ActivityItem({ profile }) {
    const history = useHistory();
    const { location: { pathname } } = history;
    let currentItem = pathname.split('/').pop();
    const { data } = useActivity(currentItem, profile._id);
    let selectedItem = data;
    const signup = async () => {
        try {
            if (selectedItem.signedup) {
                return await Swal.fire({
                    title: "คุณสมัครเข้าร่วมแล้ว",
                    icon: "warning",
                });
            }
            if (!selectedItem.open) {
                return await Swal.fire({
                    title: "ยังไม่เปิดลงทะเบียน",
                    icon: "warning",
                });
            }
            if (selectedItem.price > 0) {
                let payup = await Swal.fire({
                    title: "ต้องจ่ายคอยเพื่อเข้าร่วม",
                    icon: "info",
                    text: 'ต้องจ่าย ' + selectedItem.price + ' เพื่อเข้าร่วม'
                })
                if (!payup) {
                    return;
                }
            }
            await axios.post(window.api + '/activity/signup/', { activityId: currentItem });
            mutate('Activity', currentItem, profile._id);
            mutate(profile.lineId);
            history.push(history.location.pathname + '/completed');
        } catch (e) {
            await Swal.fire({
                title: 'ผิดพลาด!',
                text: e.response.data?.Error || e.response.data.data,
                icon: 'error'
            });
        }
    }
    return <>
        <SuspenseImg className="image margin-auto" src={selectedItem.detailImg || selectedItem.image} />
        {
            selectedItem.raffle &&
            <>
                <div className="mt-3" style={{ textAlign: 'center' }}>
                    <a onClick={signup}>
                        <SuspenseImg className="image margin-auto" src={(!selectedItem.signedup && selectedItem.open) ? "/assets/buttons/register.png" : "/assets/buttons/register-gray.png"} />
                    </a>
                </div>
                <Splitter />
            </>
        }

        {selectedItem.details &&
            <div className='p-3 mb-4' style={{ backgroundColor: '#383333', borderRadius: '12px' }}>
                <p className="gold-1 details mb-0">
                    {ReactHtmlParser(selectedItem.details)}
                </p>
            </div>
        }
        <div className="mt-3" style={{ textAlign: 'center' }}>
            <Link to='/activity' style={{ padding: '0.5rem', borderRadius: '12px', fontSize: '15px' }} className="gold-1 gold-border">
                กลับหน้ากิจกรรม
            </Link>
        </div>
    </>;
}
export default ActivityItem;