import { Link } from "react-router-dom";
import { Splitter, Carousel } from "decorators";
import { SuspenseImg } from "utility";
import { useHistory } from "react-router";
import { useActivity } from "queries";
import _ from 'lodash';

function Activity() {
    const history = useHistory();
    const { data } = useActivity();
    const activityData = _.partition(data, { type: 'now' })
    const { pathname } = history.location;
    return <>
        <SuspenseImg className="image margin-auto" src="/assets/activity/banner-activity.jpg" />
        {activityData[0].length > 0 &&
            <>
                <Splitter />
                <div className="has-text-centered gold-1">Activity Now กิจกรรมล่าสุด</div>
                {<Carousel number={1} paddingRight={50}>
                    {activityData[0].reverse().map((t, i) =>
                        <Link to={`${pathname}/${t._id}`} className="item px-1 pt-2" key={i} style={{ display: 'inline-block' }}>
                            <SuspenseImg className="img" src={t.image} />
                        </Link>
                    )}
                </Carousel>}
            </>}
        {activityData[1].length > 0 &&
            <>
                <Splitter />
                <div className="has-text-centered gold-1">Annoucement ประกาศผลกิจกรรม</div>
                {<Carousel number={1} paddingRight={50}>
                    {activityData[1].reverse().map((t, i) =>
                        <Link to={`${pathname}/${t._id}`} className="item px-1 pt-2" key={i} style={{ display: 'inline-block' }}>
                            <SuspenseImg className="img" src={t.image} />
                        </Link>
                    )}
                </Carousel>}
            </>}

    </>;
}
export default Activity;